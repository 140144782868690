import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImageDragNDropComponent } from './shared/my-filing/image-drag-n-drop/image-drag-n-drop.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  MsalInterceptor,
  MsalModule,
  MsalInterceptorConfiguration,
  MsalGuardConfiguration,
  MSAL_INSTANCE,
  MSAL_GUARD_CONFIG,
  MSAL_INTERCEPTOR_CONFIG,
  MsalService,
  MsalGuard,
  MsalBroadcastService,
  MsalRedirectComponent
} from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { NavbarComponent } from './navbar/navbar.component';
import { CoreModule } from './core/core.module';
import { SplashComponent } from './splash/splash.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SharedModule } from './shared/shared.module';
import { FileManagerComponent } from './file-manager/file-manager.component';
import { AppStoreModule } from './store/app-store.module';
import { environment } from '../environments/environment';
import { CustomHttpInterceptor } from './interceptor/custom-http.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastContainerModule, ToastrModule } from 'ngx-toastr';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SessionFacade } from './core/facades/session.facade';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { VideoDemoComponent } from './video-demo/video-demo.component';
import { AppointmentInviteComponent } from './appointment-invite/appointment-invite.component';
import { MeetingInviteComponent } from './meeting-invite/meeting-invite.component';


const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1; // Remove this line to use Angular Universal

export function loggerCallback(logLevel: LogLevel, message: string) {
  // console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.authClientId,
      authority: 'https://ribbitlearningco.b2clogin.com/ribbitlearningco.onmicrosoft.com/B2C_1_SignIn',
      knownAuthorities: ['ribbitlearningco.b2clogin.com'],
      redirectUri: environment.redirectUri
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  // protectedResourceMap.set(`${environment.api.remoteServiceBaseUrl}/*`, [`https://ribbitlearningco.onmicrosoft.com/pwa/Read`]);
  protectedResourceMap.set(`${environment.api.remoteServiceBaseUrl}/*`, [`https://ribbitlearningco.onmicrosoft.com/api/Api.Read`]);
  protectedResourceMap.set(`${environment.api.remoteServiceBaseUrl2}/*`, [`https://ribbitlearningco.onmicrosoft.com/api/Api.Read`]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [`https://ribbitlearningco.onmicrosoft.com/api/Api.Read`]
    }
  };
}

@NgModule({
  declarations: [AppComponent, NavbarComponent, SplashComponent, SidebarComponent, FileManagerComponent, VideoDemoComponent, AppointmentInviteComponent, MeetingInviteComponent],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    AppStoreModule,
    HttpClientModule,
    SharedModule,
    CoreModule,
    MsalModule,
    ToastrModule.forRoot({
      positionClass: 'inline'
    }),
    ToastContainerModule,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule
  ],
  providers: [
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      deps: [SessionFacade],
      multi: true,
      useFactory: (session: SessionFacade) => () => session.init(),
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
