import { Day } from '../models/day.model';
import { Event } from '../models/event.model';

export class CalendarCreator {
  constructor() {}

  public getMonth(monthIndex: number, year: number, events?: Array<Event>): Day[] {
    const days = [];

    let firstDayEvents: Array<Event> = [];

    if (events) {
      firstDayEvents = events.filter((event) => event.date === new Date(year, monthIndex, 1));
    }

    const firstday = this.createDay(1, monthIndex, year, firstDayEvents);

    // create empty days
    for (let i = 1; i < firstday.weekDayNumber; i++) {
      days.push({
        weekDayNumber: i,
        monthIndex,
        year
      } as Day);
    }
    days.push(firstday);

    const countDaysInMonth = new Date(year, monthIndex + 1, 0).getDate();

    for (let i = 2; i < countDaysInMonth + 1; i++) {
      let eventData;

      if (events) {
        eventData = events.filter((event) => {
          return +event.date === +new Date(year, monthIndex, i);
        });
      }

      days.push(this.createDay(i, monthIndex, year, eventData));
    }

    /// TODO: Talk to Lisa to see if she wants the calendar expanding as days grow or if fixed height
    /*if (days.length < 42) {
      for (let i = days.length; i < 42; i++) {
        days.push({
          weekDayNumber: i,
          monthIndex,
          year,
        } as Day);
      }
    }*/
    return days;
  }

  public getMonthName(monthIndex: number): string {
    switch (monthIndex) {
      case 0:
        return 'January';
      case 1:
        return 'February';
      case 2:
        return 'March';
      case 3:
        return 'April';
      case 4:
        return 'May';
      case 5:
        return 'June';
      case 6:
        return 'July';
      case 7:
        return 'August';
      case 8:
        return 'September';
      case 9:
        return 'October';
      case 10:
        return 'November';
      case 11:
        return 'December';

      default:
        return '';
    }
  }

  public getWeekDayName(weekDay: number): string {
    switch (weekDay) {
      case 0:
        return 'Su'; // Sunday
      case 1:
        return 'Mo'; // Monday
      case 2:
        return 'Tu'; // Tuesday
      case 3:
        return 'We'; // Wednesday
      case 4:
        return 'Th'; // Thursday
      case 5:
        return 'Fr'; // Friday
      case 6:
        return 'Sa'; // Saturday

      default:
        return '';
    }
  }

  private createDay(dayNumber: number, monthIndex: number, year: number, events?: Array<Event>): Day {
    const weekDayNumber = new Date(year, monthIndex, dayNumber).getDay();
    const currDate = new Date();
    if (dayNumber === currDate.getDay()) {
    }
    const isToday = dayNumber === currDate.getDate() && monthIndex === currDate.getMonth() && year === currDate.getFullYear();

    return {
      monthIndex,
      isToday,
      month: this.getMonthName(monthIndex),
      number: dayNumber,
      year,
      weekDayNumber,
      weekDayName: this.getWeekDayName(weekDayNumber),
      event: events
    };
  }
}
