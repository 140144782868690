export const appRoutesNames = {
  SPLASH: 'landing',
  HOME: 'dashboard',
  TEAM: 'workroom',
  PLN: 'the-lounge',
  FILING: 'my-cabinet',
  COACHES: 'coaches',
  REPORTS: 'reports',
  USERPROFILE: 'user-profile',
  VIDEODEMO: 'video-demo',
  SUCCESS: 'success',
  ERROR: 'error',
  APPOINTMENTINVITE: 'appointment-invite',
  MEETINGINVITE: 'meeting-invite',
  LOGIN: 'login'
};
