import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { login } from '../core/models/login';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private httpClient: HttpClient) { 
    
  }
  
  checklogin(body: login) {
    const url = `${environment.api.remoteServiceBaseUrl2}/token`;
    return this.httpClient.post(url,body);
  }
}
