import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { LayoutActionTypes } from '../store/actions/layout.action';
import { AppState } from '../store/reducers';
import * as fromLayout from '../store/selectors/layout.selector';
import { UserProfileService } from '../core/services/user-profile.service';
import { ImageInfo, ProfileImage, User, UserGrade } from 'src/app/core/models/user-profile-response.model';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @Input() username: string | null = null;
  fName = '';
  lName = '';
  userProfile!: User;
  profileImages!: ProfileImage[];
  loading = true;
  grade = '';
  leftSidebarState = false;
  goal1Width = 105;
  goal2Width = this.goal1Width + 15;
  goal3Width = this.goal2Width + 16;
  imgWidth = this.goal1Width - 30;

  constructor(private store: Store<AppState>, private ups: UserProfileService) {
    this.store.select(fromLayout.getLeftSidenavState).subscribe((state) => {
      this.leftSidebarState = state;
    });
  }

  ngOnInit(): void {
    if (this.username !== null) {
      this.setDisplayName(this.username);
    }
    this.ups.getUserProfile().subscribe((result: any) => {
      this.userProfile = result.userProfile;
      this.profileImages = result.profileImaages.sort((a: ProfileImage, b: ProfileImage) => a.imageLocation - b.imageLocation);
      this.grade = this.formatGrades(this.userProfile.userGrades);
      this.loading = false;
    });
  }

  formatGrades(userGrades: UserGrade[]) {
    return userGrades.reduce((pv, cv, index) => {
      return index < userGrades.length - 1 ? pv + `${cv.gradeName},` : pv + cv.gradeName;
    }, '');
  }

  setDisplayName(username: string): void {
    const splitUser = username.split(' ');
    this.fName = splitUser[0];
    this.lName = splitUser.length > 1 ? splitUser[1] : '';
  }

  toggleSideBar(): void {
    if (this.leftSidebarState) {
      this.goal1Width = 105;
      this.goal2Width = this.goal1Width + 15;
      this.goal3Width = this.goal2Width + 16;
      this.imgWidth = this.goal1Width - 30;
      this.store.dispatch(LayoutActionTypes.closeLeftSideNav());
    } else {
      this.goal1Width = 130;
      this.goal2Width = this.goal1Width + 15;
      this.goal3Width = this.goal2Width + 16;
      this.imgWidth = this.goal1Width - 30;
      this.store.dispatch(LayoutActionTypes.openLeftSideNav());
    }
  }
}
