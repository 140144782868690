<nav>
  <h2><a class="navbar-brand" [routerLink]="['/home']">Thriiiv</a></h2>
  <h3 *ngIf="loggedIn">Hello {{ (userProfile!=null && userProfile.firstName != null) ? (userProfile.firstName + " " + userProfile.lastName) : "" }}!</h3>
  <div class="right-nav">
    <h4 *ngIf="loggedIn">It's {{ today }}</h4>
    <div class="notifications-bell" *ngIf="loggedIn">
      <a href="#" (click)="showNotification()">
        <i class="icon-bell"></i> <i class="icon-bell outer"></i>
        <span class="notification-holder">
          <span class="notification-count">{{NotificationCount}}</span>
        </span>
      </a>
      <div class="notification" *ngIf="notificationshow && NotificationCount>0">
        
        <div class="notification-content">
          <div class="close-btn" (click)="showNotification()"><i class="fa fa-close"></i></div>
          <p *ngFor="let item of NotificationData; let i = index">{{ (item.appointment.inviteAccepted)?("You have an appointment with " + ((item.appointment.mentorId != userId)?item.appointment.mentorName:item.appointment.menteeName) + " at " + getMMDDYYYY(item.dateTime)):"" }}
            <button (click)="acceptRequest"></button>
          </p>
        </div>
      </div>
    </div>
    <div class="login-logout">
      <div class="btn-group" dropdown *ngIf="loggedIn">
        <a href dropdownToggle (click)="false"><img src="{{ profileImages?profileImages[5].imageUrl:'../../assets/images.jpeg'}}" height="48" width="48"></a>
        
        <ul *dropdownMenu class="dropdown-menu dropdown-menu-left" role="menu">
          <li><a class="dropdown-item" href="javascript: void(0);" [routerLink]="['/user-profile']">Profile</a></li>
          <li><a class="dropdown-item" href="javascript: void(0);" (click)="logout()">Log Out</a></li>
        </ul>
      </div>
    </div>
  </div>
</nav>
