import { Component, OnDestroy, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, NavigationStart } from '@angular/router';
import { AuthService } from './core/auth/auth.service';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { environment } from './../environments/environment';
import { LoginComponentComponent } from '../app/login-component/login-component.component';

const API_ENDPOINT = 'https://thriiive-apis-sandbox.azurewebsites.net/User/information';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy, OnInit {
  title = 'thriiiv';
  isLoggedIn = false;
  accountName = '';
  passwordResetAuthority = 'https://ribbitlearningco.b2clogin.com/ribbitlearningco.onmicrosoft.com/B2C_1_PasswordReset';
  subscriptions: Subscription[] = [];
  response: any;
  isappointmentpage: boolean = false;
  previousUrl: string = '';
  @ViewChild(ToastContainerDirective, { static: true })
  toastContainer: ToastContainerDirective | undefined;
  objhttp: any;
  baseUrl = `${environment.api.remoteServiceBaseUrl}`;

  constructor(public authService: AuthService, private router: Router, private renderer: Renderer2, private toastrService: ToastrService, public http: HttpClient) {
    this.objhttp = http;

    if (window.location.pathname == "/appointment-invite" || window.location.pathname == "/meeting-invite") {
      this.isappointmentpage = true;
    }
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (this.previousUrl) {
          this.renderer.removeClass(document.body, this.previousUrl);
        }
        let currentUrlSlug = event.url.slice(1);
        if (currentUrlSlug) {
          this.renderer.addClass(document.body, currentUrlSlug);
        }
        this.previousUrl = currentUrlSlug;
      }
    });
  }

  ngOnInit(): void {
    console.log('should be called only once');
    if (!this.isappointmentpage) {
      this.authService.login();
      this.authService.updateLoggedInStatus();
    }
    if (this.toastContainer) {
      this.toastrService.overlayContainer = this.toastContainer;
    }
  }

  login() {
    this.authService.login();
  }

  logout() {
    this.authService.logout();
  }

  ngOnDestroy(): void { }
}
