import { MsalGuard } from '@azure/msal-angular';
import { appRoutesNames } from './app.routes.names';
import { AppointmentInviteComponent } from './appointment-invite/appointment-invite.component';
import { LoginComponentComponent } from './login-component/login-component.component';
import { MeetingInviteComponent } from './meeting-invite/meeting-invite.component';

type PathMatch = "full" | "prefix" | undefined;

export const appRoutes = [
  {
    path: appRoutesNames.HOME,
    canLoad: [MsalGuard],
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule)
  },
  {
    path: appRoutesNames.TEAM,
    canLoad: [MsalGuard],
    loadChildren: () => import('./team/team.module').then((m) => m.TeamModule)
  },
  {
    path: appRoutesNames.USERPROFILE,
    canLoad: [MsalGuard],
    loadChildren: () => import('./user-profile/user-profile.module').then((m) => m.UserProfileModule)
  },
  {
    path: appRoutesNames.PLN,
    canLoad: [MsalGuard],
    loadChildren: () => import('./pln/pln.module').then((m) => m.PlnModule)
  },
  {
    path: appRoutesNames.FILING,
    canLoad: [MsalGuard],
    loadChildren: () => import('./filing/filing.module').then((m) => m.FilingModule)
  },
  {
    path: appRoutesNames.COACHES,
    canLoad: [MsalGuard],
    loadChildren: () => import('./coaches/coaches.module').then((m) => m.CoachesModule)
  },
  {
    path: appRoutesNames.REPORTS,
    canLoad: [MsalGuard],
    loadChildren: () => import('./reports/reports.module').then((m) => m.ReportssModule)
  },
  {
    path: appRoutesNames.VIDEODEMO,
    canLoad: [MsalGuard],
    loadChildren: () => import('./video-demo/video-demo.module').then((m) => m.VideoDemoModule)
  },
  {
    path: appRoutesNames.SUCCESS,
    canLoad: [MsalGuard],
    loadChildren: () => import('./success/success.module').then((m) => m.SuccessModule)
  },
  {
    path: appRoutesNames.ERROR,
    canLoad: [MsalGuard],
    loadChildren: () => import('./error/error.module').then((m) => m.ErrorModule)
  },
  {
    path: '',
    canLoad: [MsalGuard],
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule)
  },
  {
    path: appRoutesNames.APPOINTMENTINVITE,
    component: AppointmentInviteComponent
  },
  {
    path: appRoutesNames.MEETINGINVITE,
    component: MeetingInviteComponent
  },
  {
    path: appRoutesNames.LOGIN,
    component: LoginComponentComponent
  }
];
