import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';
import { environment } from '../../../environments/environment';
import { ApiResponse } from '../models/api-response.model';
import {  AppointmentInfo,MeetingInfo,GetMeetingInfo } from '../models/coach.model';
import { Coach } from '../models/user-profile-response.model';
import { HttpCachingService } from './http-caching.service';

@Injectable({
  providedIn: 'root'
})
export class CoachService {
  baseUrl = `${environment.api.remoteServiceBaseUrl}`;
  constructor(private httpClient: HttpClient, private httpCachingService: HttpCachingService) {
    
  }

  seachForCoaches(isInCache = false): Observable<Coach[]> {
    const endPoint = '/Coach/search';
    const url = this.getUrl(endPoint);

    if (isInCache) {
      this.httpCachingService.addCachingUrl(url);
    }

    return this.httpClient.get<Coach[]>(url).pipe(map((data: any) => (data as ApiResponse).result as Coach[]));
  }

  assignCoach(coachId: string, goalId: number): Observable<any> {
    const endPoint = '/Coach/assign';
    const url = this.getUrl(endPoint);

    return this.httpClient.post(url, {coachId, goalId}).pipe(map((data: any) => (data as ApiResponse).result as any));
  } 

  getUrl(endPoint: string): string {
    return `${this.baseUrl}/${endPoint}`;
  }

  getCoach() {
    const url = `${environment.api.remoteServiceBaseUrl2}/User/all`;
    return this.httpClient.get(url);
  }

  searchCoaches(e:any){
    const url = `${environment.api.remoteServiceBaseUrl2}/User/all?name=`+e;
    return this.httpClient.get(url);
  }

  postAppointment(body: AppointmentInfo) {
    const url = `${environment.api.remoteServiceBaseUrl2}/Appointment`;
    return this.httpClient.post(url, body);
  }

  sendMeetingRequest(body: MeetingInfo) {
    const url = `${environment.api.remoteServiceBaseUrl2}/Appointment/RequestMeeting`;
    return this.httpClient.post(url, body);
  }

  getMeetingRequest() {
    const url = `${environment.api.remoteServiceBaseUrl2}/Appointment/RequestMeetings`;
    return this.httpClient.get(url);
  }

  getAllAppointment(){
    const url = `${environment.api.remoteServiceBaseUrl2}/Appointment/all`;
    return this.httpClient.get(url);
  }
  
  getFutureAppointment(){
    const url = `${environment.api.remoteServiceBaseUrl2}/Appointment/all?date=`+this.getCurrentDate();
    return this.httpClient.get(url);
  }

  deleteAppointment(id:any){
    const url = `${environment.api.remoteServiceBaseUrl2}/Appointment/`+id;
    return this.httpClient.delete(url);
  }

  getNotification(e:any){
    const url = `${environment.api.remoteServiceBaseUrl2}/Appointment/status/1`;
    return this.httpClient.get(url);
  }

  getIndicator(){
    const url = `${environment.api.remoteServiceBaseUrl}/Resource/by/indicator/{indicatorId}`;
    return this.httpClient.get(url);
  }

  getCurrentDate():string{
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0');
    let yyyy = today.getFullYear();

    return yyyy + '-' + mm + '-' + dd;
  }

  updateAppointmentStatus(appointmentid:number, status:boolean){
    const url = `${environment.api.remoteServiceBaseUrl2}/Appointment/invite/`+appointmentid+"/"+status;
    return this.httpClient.put(url,{status: status});
  }

  updateMeetingStatus(appointmentid:number, status:boolean){
    const url = `${environment.api.remoteServiceBaseUrl2}/Appointment/RequestMeetingInvite/`+ appointmentid + `/` + status;
    return this.httpClient.put(url,{status: status});
  }

  getTodayAppointment(){
    const url = `${environment.api.remoteServiceBaseUrl2}/Appointment/today`;
    return this.httpClient.get(url);
  }
}
