import { NgModule } from '@angular/core';
import { CircularProgressComponent } from '../circular-progress/circular-progress.component';
import { CalendarComponent } from '../calendar/calendar.component';
import { CalendarCreator } from '../calendar/service/calendar-creator.service';
import { SidebarWatchDirective } from '../directives/sidebar-watch.directive';
import { CarouselModule, CarouselConfig } from 'ngx-bootstrap/carousel';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { SafeHtmlPipe } from './pipes/safeHtml.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FileFolderComponent } from '../file-folder/file-folder.component';
import { ModalComponent } from './modal/modal.component';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { RatingModule, RatingConfig } from 'ngx-bootstrap/rating';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { SortByPipe } from './pipes/sortBy.pipe';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { UserTaskComponent } from './components/user-task/user-task.component';
import { MyFilingComponent } from './my-filing/my-filing.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ImageDragNDropComponent } from './my-filing/image-drag-n-drop/image-drag-n-drop.component';
import { FilesShownComponent } from './my-filing/doughnut-chart/doughnut-chart.component';
import { SortByComponent } from './my-filing/sort-by/sort-by.component';
import { TilesComponent } from './my-filing/tiles/tiles.component';
import { StorageCapacityComponent } from './my-filing/storage-capacity/storage-capacity.component';
import { ListItemComponent } from '../file-folder/components/list-item/list-item.component';
import { SortButtonComponent } from './my-filing/sort-by/sort-button/sort-button.component';
import { ListDragComponent } from '../file-folder/components/list-item/list-drag/list-drag.component';
import { TextBoxDirective } from './my-filing/image-drag-n-drop/directives/text-box.directive';
import { FileCabinetUploadModalComponent } from './components/file-cabinet-upload-modal/file-cabinet-upload-modal.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { UserInfoComponent } from './my-filing/tiles/components/user-info/user-info.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NotesComponent } from './components/notes/notes.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { ColorPickerModule } from 'ngx-color-picker';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { BulletTabDirective } from './components/notes/bullet-tab.directive';
import { OrderListTabDirective } from './components/notes/order-list-tab.directive';
import { CommonModule } from '@angular/common';


@NgModule({
  declarations: [
    CircularProgressComponent,
    CalendarComponent,
    SidebarWatchDirective,
    SafeHtmlPipe,
    SortByPipe,
    FileFolderComponent,
    ModalComponent,
    ProgressBarComponent,
    UserTaskComponent,
    MyFilingComponent,
    ImageDragNDropComponent,
    FilesShownComponent,
    SortByComponent,
    TilesComponent,
    StorageCapacityComponent,
    ListItemComponent,
    SortButtonComponent,
    ListDragComponent,
    TextBoxDirective,
    FileCabinetUploadModalComponent,
    UserInfoComponent,
    NotesComponent,
    BulletTabDirective,
    OrderListTabDirective
  ],
  imports: [
    CommonModule,
    CarouselModule,
    TabsModule.forRoot(),
    FormsModule,
    DragDropModule,
    ModalModule.forRoot(),
    RatingModule,
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgxDropzoneModule,
    NgbModule,
    MatCardModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    EditorModule,
    ColorPickerModule,
    MatToolbarModule,
    MatListModule,
    MatDividerModule,
    MatPaginatorModule,
    MatCheckboxModule
  ],
  exports: [
    BsDropdownModule,
    CommonModule,
    BsDropdownModule,
    FormsModule,
    ReactiveFormsModule,
    CarouselModule,
    TabsModule,
    FormsModule,
    DragDropModule,
    BsDatepickerModule,
    ModalModule,
    RatingModule,
    TooltipModule,
    CircularProgressComponent,
    CalendarComponent,
    ModalComponent,
    SidebarWatchDirective,
    SafeHtmlPipe,
    SortByPipe, 
    FileFolderComponent,
    ProgressBarComponent,
    UserTaskComponent,
    MyFilingComponent,
    ListItemComponent,
    NgxDropzoneModule,
    ImageDragNDropComponent,
    ScrollingModule,
  ],
  providers: [
    CalendarCreator,
    RatingConfig,
    { provide: CarouselConfig, useValue: { interval: 10000, noPause: false, showIndicators: true, pauseOnFocus: true } }
  ]
})
export class SharedModule { }
