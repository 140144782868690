import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { LoginService } from '../../app/login-component/login.service';
import { login } from '../core/models/login';

@Component({
  selector: 'app-login-component',
  templateUrl: './login-component.component.html',
  styleUrls: ['./login-component.component.scss']
})
export class LoginComponentComponent implements OnInit {

  searchData: string = '';

  public loginForm = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
  });

  constructor(private ls: LoginService) { }

  ngOnInit(): void {
  }

  onSubmit() {
    let email = $("#email").val();
    let password = $("#password").val();

    const login: login = {
      email: ""+email,
      password: ""+password
    }

    $.ajax({
      url: 'https://apiribbitlearningv2.azurewebsites.net/token',
      type: 'POST',
      data: JSON.stringify({
        email: email,
        password: password
      }),
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
      async: false,
      success: function (msg) {
        console.log(msg);
      }
    });
    

    // this.ls.checklogin(login).subscribe((result: any) => {
    //   alert("Hi");
    //   console.log("Hi");
    // });
    
    return false;
  }

  login(){

  }

}
