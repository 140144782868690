<div class="container" [ngClass]="{ 'is-logged-in': authService.loggedIn }" *ngIf="!isappointmentpage else appointmentpage">
  <app-navbar [loggedIn]="authService.loggedIn" (loginClicked)="login()" (logoutClicked)="logout()"></app-navbar>
  <app-sidebar *ngIf="authService.loggedIn" [username]="accountName"></app-sidebar>
  <div class="app-content" *ngIf="authService.loggedIn else loginscreen">
    <router-outlet></router-outlet>
    <div aria-live="polite" toastContainer></div>
  </div>
</div>
<ng-template #appointmentpage>
  <app-appointment-invite></app-appointment-invite>
</ng-template>
<ng-template #loginscreen>
  <router-outlet></router-outlet>
</ng-template>