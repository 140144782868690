<!-- <P>
    <ngb-progressbar type="success" [value]="progressvalue" *ngIf="progressvalue" [striped]="true"></ngb-progressbar>
</P> -->
<P>
    <ngb-progressbar type="success" [value]="progressvalue" [striped]="true"></ngb-progressbar>
</P>
<!-- <P>
    <ngb-progressbar type="success" [value]="50" [striped]="true"></ngb-progressbar>
</P> -->

<div class="list keyword" (click)="fileClicked()" *ngFor="let listItem of tempFiles">
    <div class="list-item">
        {{listItem.name.substring(0, 12)}} <span class="delete-keyword"
            (click)="deleteUploadedFile($event, listItem)">X</span>
    </div>
</div>

<form action="">
    <div class="">
        <h2>Edit File detials</h2>
    </div>
    <div class="form-item filename-title">
        <label for="filename">File Name</label><br>
        <input class="form-control" type="text" name="" id="filename" placeholder="Name Your File"
            value="{{filenameToDisplay}}">
    </div>
    <div class="formBorder" class="form-item filename-keywords">
        <div class="keywords-header">
            Keywords Associated
        </div>
        <div class="tags-wrapper">
            <div class="keyword-wrapper">
                <div [ngClass]="getActiveClass(false, keywords)" [ngStyle]="isActiveStyle(keywords)"
                    (click)="keywordClicked2($event, keywords)" *ngFor="let keywords of tempKeywords" class="keyword">
                    {{keywords.title}}
                </div>
            </div>
        </div>
        <div class="keyword-add-tag">
            <div class="tag-add-wrapper">
                <div class="textbox-area">
                    <input (keydown)="addTagInputKeyup($event)" class="form-control" type="text"
                        placeholder="Add new Keyword...">
                </div>
                <div class="submit-button-area">
                    <button type="button" class="btn btn-primary" (click)="addKeyword($event)">Add Keyword</button>
                </div>
                <!-- <button>Hello World Lets Gooooooo!</button> -->
            </div>

        </div>
    </div>
    <div class="form-item filename-select-file-cabintet-directory">
        <div class="file-name-select-file-cabinet-directory-header">
            Select a folder to place
        </div>
        <div *ngFor="let buttons of selectButton" class="directory-wrapper">
            <div class="buttons-wrapper">
                <div class="button" *ngIf="buttons.virtualPath !=''; else elseTag" (click)="toggleDirectory($event)">
                    {{ buttons.virtualPath }}
                </div>
                <ng-template #elseTag>
                    <div class="button" (click)="toggleDirectory($event)">No Name{{buttons.virtualPath}}</div>
                </ng-template>
            </div>
           
        </div>
    </div>
    <div class="add-to-profile">
        <!-- Default switch -->
        <div class="custom-control custom-switch">
            <input (change)="switchAddToProfile($event)" type="checkbox" class="custom-control-input"
                id="customSwitches">
            <label class="custom-control-label" for="customSwitches">Add to Profile</label>
        </div>
    </div>
    <div class="form-buttons">
        <!-- <P>
            <ngb-progressbar type="success" [value]="progressvalue" *ngIf="progressvalue" [striped]="true">
            </ngb-progressbar>
        </P> -->
        <div class="form-button-cancel">
            <input type="button" value="Cancel" (click)="cancelPost()" class="form-cancel-button btn">
        </div>
        <div class="form-button-submit">
            <input type="button" value="Update Info" (click)="updateInfo($event)" class="form-submit-button btn">
        </div>
        <!-- <P>
            <ngb-progressbar type="success" [value]="50"></ngb-progressbar>
        </P> -->

        <!-- <div class="form-button-cancel"><button (click)="cancelPost()" class="form-cancel-button btn">Cancel</button></div>
        <div class="form-button-submit"><button (click)="updateInfo()" class="form-submit-button btn">Update Info</button></div> -->
    </div>
</form>