<div class="container">
    <div class="row d-flex justify-content-center align-items-center h-100">
        <div class="col-xl-10">
            <div class="row">
                <div class="col-xl-6 loginform">
                    <div align="center">
                        <img src="../../assets/logo.png" style="width:200px;height:200px;" />
                        
                        <p style="margin-top:50px;">We are Ribbit Learning</p>
                        <h2 style="margin-top:20px;">Sign in</h2>
                    </div>
                    <div class="form">
                        <form (ngSubmit)="onSubmit()">
                            <input id="email" ngModel type="email" class="textbox" maxlength="100"  placeholder="Email Address" formControlName="email" />
                            <input id="password" ngModel type="password" class="textbox" maxlength="50" placeholder="Password" formControlName="password" />
                            
                            <p><a href="#">Forgot your password?</a></p>
                            <p style="margin-top:20px;">
                                <button class="loginbtn" (click)="onSubmit()">Sign In</button>
                            </p>
                        </form>
                        <p style="margin-top:20px;">
                            New to Ribbit? <a href="#">Contact Us</a>
                        </p>
                    </div>
                </div>
                <div class="col-xl-6 sidebar">
                    <img src="../../assets/sidebar.png" />
                </div>
            </div>
        </div>
        
    </div>
</div>
