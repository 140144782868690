import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-appointment-invite',
  templateUrl: './appointment-invite.component.html',
  styleUrls: ['./appointment-invite.component.scss']
})
export class AppointmentInviteComponent implements OnInit {
  baseUrl = `${environment.api.remoteServiceBaseUrl}`;
  objhttp: any;

  constructor(public http: HttpClient, private toastr: ToastrService) {
    this.objhttp = http;
  }

  ngOnInit(): void {
    if (window.location.pathname == "/meeting-invite") {
      let urlParams = new URLSearchParams(window.location.search);
      let q = urlParams.get('q');
      const url = `https://apiribbitlearningv2.azurewebsites.net/Appointment/RequestMeetingInviteByMail`;
      $.ajax({
        url: url,
        type: 'POST',
        data: JSON.stringify({
          "Token": q
        }),
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        async: false,
        success: function (msg) {

        }
      });
    } else {
      this.accept();
    }
  }

  accept() {
    let urlParams = new URLSearchParams(window.location.search);
    let q = urlParams.get('q');
    const url = `https://apiribbitlearningv2.azurewebsites.net/Appointment/InviteByMail`;
    $.ajax({
      url: url,
      type: 'POST',
      data: JSON.stringify({
        "Token": q
      }),
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
      async: false,
      success: function (msg) {
        this.toastr.success(`Appointment created successfully`, undefined, {
          positionClass: 'toast-top-right',
          timeOut: 2000
        });
      }
    });

    return false;
  }

}
