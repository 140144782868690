import { HttpInterceptor, HttpErrorResponse, HttpEvent, HttpResponse, HttpRequest, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Observable, of, throwError } from 'rxjs';
import { tap, catchError, finalize } from 'rxjs/operators';
import { HttpCachingService } from '../core/services/http-caching.service';

@Injectable({
  providedIn: 'root'
})
export class CustomHttpInterceptor implements HttpInterceptor {
  constructor(
    private readonly router: Router,
    private readonly httpCachingService: HttpCachingService,
    private authService: MsalService
  ) // private readonly loaderService: LoaderService
  {}

  private createHttpResponseFromHttpErrorResponse(
    httpErrorResponse: HttpErrorResponse,
    status: number | null = null
  ): Observable<HttpEvent<any>> {
    const url = httpErrorResponse.url !== null ? httpErrorResponse.url : undefined;
    return of(
      new HttpResponse({
        headers: httpErrorResponse.headers,
        status: status ?? httpErrorResponse.status,
        statusText: httpErrorResponse.statusText,
        url,
        body: httpErrorResponse.error
      })
    );
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const newRequest = request.clone({
      url: request.url.replace('http://', 'https://'),
      setHeaders: {
        'Content-Type': 'application/json'
      },
    });

    if (newRequest.method !== 'GET' || !this.httpCachingService.existsCachingUrl(newRequest.url)) {
      return this.requestHandler(newRequest, next);
    }

    const cacheEntry = this.httpCachingService.getCacheEntry(newRequest.urlWithParams);

    if (cacheEntry) {
      console.log(
        `HTTP ${newRequest.method} ${newRequest.url} ${newRequest.body ? 'BODY: ' + JSON.stringify(newRequest.body) : ''} (CACHE)`
      );
    }

    return cacheEntry ? (cacheEntry instanceof Observable ? cacheEntry : of(cacheEntry.clone())) : this.requestHandler(newRequest, next);
  }

  private requestHandler(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const startRequestTime = Date.now();
    let isRequestInSucces = true;

    // this.loaderService.show();

    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.httpCachingService.deleteExpiredCacheEntries();

          if (this.httpCachingService.existsCachingUrl(request.url)) {
            this.httpCachingService.setCacheEntry(request.urlWithParams, event);
          }
        }
      }),
      catchError((httpErrorResponse: HttpErrorResponse): Observable<HttpEvent<any>> => {
        isRequestInSucces = false;
        let errorMessage: string = '';

        if (httpErrorResponse.error instanceof ErrorEvent) {
          errorMessage = httpErrorResponse.error.message;
        } else {
          switch (httpErrorResponse.status) {
            case 401:
              console.error('(CustomHttpInterceptor)', 'Unauthorized request');
              this.router.navigate(['/error/unauthorized']);
              break;
            case 403:
              console.error('(CustomHttpInterceptor)', 'Forbidden request');
              this.router.navigate(['/error/forbidden']);
              break;
            case 404:
              return this.createHttpResponseFromHttpErrorResponse(httpErrorResponse);
            default:
              errorMessage = httpErrorResponse.message || httpErrorResponse.statusText;
          }
        }

        return throwError(errorMessage);
      }),
      finalize(() => {
        // this.loaderService.hide();

        const elapsedRequestTime = Date.now() - startRequestTime;

        console.log(
          `HTTP ${request.method} ${request.url} ${request.body ? 'BODY: ' + JSON.stringify(request.body) : ''} (${
            isRequestInSucces ? 'SUCCESS' : 'ECHEC'
          } ${elapsedRequestTime}ms)`
        );
      })
    );
  }
}
